import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { VenueModel } from "../../models/venue.model";
import { StorageService } from "src/app/common/storage.service";
import { MenuItemModel } from "src/app/models/menu-item.model";
import { MenuModel } from "src/app/models/menu.model";
import { MenuService } from "src/app/menu-item/menu.service";
import { TabsetComponent } from "ngx-bootstrap";

@Component({
  selector: "app-qr-menu",
  templateUrl: "./qr.menu.component.html",
  styleUrls: ["./qr.menu.component.scss"],
})
export class QrMenuComponent implements OnInit, AfterViewInit {
  @Input() venue: VenueModel;
  @Input() shouldShowCart?: boolean;
  @Input() TABS?: {[key: string]: string};
  @Input() changeTab?: (tab: string) => void;

  menueTabs = [];
  menues: MenuModel[];
  currentCategorie!: any;
  menusLoading = false;
  constructor(
    private storageService: StorageService,
    private menuService: MenuService
  ) {}
  async ngOnInit() {}

  async ngAfterViewInit() {
    await this.loadMenus();
    await this.getMenuTypes();
    this.currentCategorie = this.menueTabs[0];
  }
  async getMenuTypes() {
    this.menusLoading = true;
    const menuTypes = await this.menuService.getMenuTypes(this.venue.id, true);
    menuTypes
      .filter(
        (menuType) => menuType.disabled === false && menuType.menuItems > 0
      )
      .forEach((element) => {
        const items = this.getMenuItems(element.key);
        const menu = this.menues.filter((item) => item.type === element.key)[0];
        if (items && items.length && this.doesMenuContainItems(items[0])) {
          this.menueTabs.push({
            key: element.key,
            label: element.value,
            items,
            priority: menu.priority,
          });
        }
      });
    this.menusLoading = false;
  }
  async loadMenus() {
    const allMenues = await this.menuService.getAllMenues(this.venue.id, false);
    this.menues = allMenues
      .filter((m) => !m.disabled)
      .sort((a, b) => {
        if (a.priority > b.priority) {
          return 1;
        }
        if (b.priority > a.priority) {
          return -1;
        } else {
          return 0;
        }
      });
  }
  getMenuItems(menu: string) {
    return this.menues.filter((x) => x.type === menu && !x.disabled);
  }
  private doesMenuContainItems(menu: MenuModel) {
    if (menu.categories && menu.categories.length) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < menu.categories.length; i++) {
        if (menu.categories[i].items && menu.categories[i].items.length) {
          return true;
        }
      }
    }
    return false;
  }
  moveCategorie(toLeft?: boolean) {
    const index = this.menueTabs.findIndex(
      (c) => c.key === this.currentCategorie.key
    );
    if (toLeft) {
      if (index === 0) {
        return;
      }
      return this.changeCategorie(this.menueTabs[index - 1].key);
    }
    if (index === this.menueTabs.length - 1) {
      return;
    }
    this.changeCategorie(this.menueTabs[index + 1].key);
  }
  changeCategorie(tagKey: string) {
    this.currentCategorie = this.menueTabs.find((c) => c.key === tagKey);
    console.log(this.currentCategorie, "currentCategorie");
  }
  shouldShowCategory(category) {
    const available = category.items.filter((item) => item.available);
    return category.items && category.items.length && available.length;
  }
  addItemToCart(item) {
    const cart = this.storageService.getItem("cart") || [];
    const cartItem = cart.find((c) => c.id === item.id);
    if (cartItem) {
      cartItem.quantity += 1;
    } else {
      cart.push({ ...item, quantity: 1 });
    }
    this.storageService.setItem("cart", cart);
  }
  removeFromCart(item) {
    const cart = this.storageService.getItem("cart") || [];
    const cartToSave = cart.filter((c) => {
      if (c.id === item.id && c.quantity > 1) {
        c.quantity -= 1;
        return true;
      }
      return c.id !== item.id;
    });
    this.storageService.setItem("cart", cartToSave);
  }
  getCartTotal() {
    const cart = this.storageService.getItem("cart") || [];
    return cart.reduce((acc, item) => {
      return acc + this.getLowestPrice(item) * item.quantity;
    }, 0);
  }
  getLowestPrice(menu: MenuItemModel) {
    const prices = [];
    if (menu && menu.options) {
      menu.options.forEach((option) => {
        if (option.price) {
          prices.push(option.price.value);
        }
      });
    }
    return Math.min(...prices);
  }
  goToPayment() {
    this.changeTab(this.TABS.payment);
  }
}
