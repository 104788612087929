import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/common/notification.service";
import { StorageService } from "src/app/common/storage.service";
import { EventService } from "src/app/event/event.service";
import { BasketService, IBasket } from "src/app/mobile-ordering/basket/basket.service";
import { EventModel } from "src/app/models/event.model";
import { UserModel } from "src/app/models/user.model";
import { VenueModel } from "src/app/models/venue.model";
import { OrderService } from "src/app/order/order.service";
import { VenueService } from "src/app/venue/venue.service";

@Component({
  selector: "app-payment-success-modal",
  templateUrl: "./payment.success.modal.component.html",
  styleUrls: ["./payment.success.modal.component.scss"],
})
export class PaymentSuccesModalComponent {
  @Input() venue!: VenueModel;
  @Input() tableNumber!: string;
  @Input() event!: EventModel;
  @Input() tips!: string;
  @Input() tax!: string;
  @Input() user!: UserModel;
  @Input() basket!: IBasket;
  @Input() sessionId!: string;
  session: any;

  constructor(
    private router: Router,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private storageService: StorageService,
  ) {}

  async ngOnInit() {
  }
  async createOrder() {
    if (!this.session) {
      this.notificationService.error('There are some error creating the order');
      window.scroll(0, 150);
      return;
    }
    try {
      const stripePi = this.session.payment_intent.id;
      const ordersIds = this.session.metadata.ordersId;
      await this.orderService.createOrder(
        this.user.id,
        this.venue.id,
        ordersIds,
        this.tableNumber,
        this.event.id,
        this.tableNumber,
        this.tableNumber,
        this.basket.items,
        {},
        Number(this.tips),
        null,
        stripePi,
        this.sessionId,
        this.user.token
      );
    } catch (error) {
      
    }
  }

  isShowModal = true;
  navigateToHome() {
    this.storageService.deleteItem("temporary-basket");
    return this.router.navigate([
      `/mobile-qr/${this.venue.slug}/${this.tableNumber}`,
    ]);
  }
  close() {
    this.isShowModal = false;
  }
}
