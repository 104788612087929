import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { VenueModel } from "../../models/venue.model";
import { MenuModel } from "../../models/menu.model";
import { ActivatedRoute } from "@angular/router";
import { EventModel } from "src/app/models/event.model";
import { StorageService } from "src/app/common/storage.service";
import { MenuItemModel } from "src/app/models/menu-item.model";
import { VenueService } from "src/app/venue/venue.service";
import { EventService } from "src/app/event/event.service";
import { UserModel } from "src/app/models/user.model";
import { OrderService } from "src/app/order/order.service";
import { NotificationService } from "src/app/common/notification.service";

@Component({
  selector: "app-qr-payment-tab",
  templateUrl: "./tab.payment.component.html",
  styleUrls: ["./tab.payment.component.scss"],
})
export class QRPaymentTabComponent implements OnInit {
  venue: VenueModel;
  venueSlug: string;
  event: EventModel;
  tableNumber: string;
  // @Input() event: EventModel;
  cart: any = [];
  isModalShown = true;
  orders!: any[]
  tips: string;
  tax: string;
  user: UserModel
  sessionId: string;
  session: any;

  TABS = {
    MY_ORDERS: "myOrders",
    SPLIT_ORDERS: "splitOrders",
    PAY: "pay",
  };
  constructor(
    private route: ActivatedRoute,
    private venueService: VenueService,
    private eventService: EventService,
    private storageService: StorageService,
    private orderService: OrderService,
    private notificationService: NotificationService
  ) {}
  async ngOnInit() {
    const slug = this.route.snapshot.paramMap.get("slug");
    this.venue = await this.venueService.getBySlug(slug);
    this.venueSlug = slug;
    const user = this.storageService.getItem("temporary-user");
    this.user = user
    const eventId = this.route.snapshot.paramMap.get("eventId");
    const event = await this.eventService.getById(eventId, user.token);
    this.event = event;
    this.tips = this.route.snapshot.queryParamMap.get("tips");
    this.tax = this.route.snapshot.queryParamMap.get("tax");
    this.cart = this.storageService.getItem("temporary-basket");
    this.sessionId = this.route.snapshot.paramMap.get("sessionId");
    
    this.tableNumber = this.route.snapshot.paramMap.get("tableNo");
    this.session = await this.orderService.getCheckoutInfo(this.sessionId);
    
    await this.createOrder();
  }

  async createOrder() {
    if (!this.session) {
      this.notificationService.error('There are some error creating the order');
      window.scroll(0, 150);
      return;
    }
    try {
      const stripePi = this.session.payment_intent.id;
      const ordersIds = this.session.metadata.ordersId;
      await this.orderService.createOrder(
        this.user.id,
        this.venue.id,
        ordersIds,
        this.tableNumber,
        this.event.id,
        this.tableNumber,
        this.tableNumber,
        this.cart.items,
        {},
        Number(this.tips),
        null,
        stripePi,
        this.sessionId,
        this.user.token
      );
    } catch (error) {
      console.error(error);
    }
  }
  getLowestPrice(menu: MenuItemModel) {
    const prices = [];
    if (menu.options) {
      menu.options.forEach((option) => {
        if (option.price) {
          prices.push(option.price.value);
        }
      });
    }
    return Math.min(...prices);
  }
  changeTab(event, tab: string) {
    const target = event.target;

    document
      .querySelector(`.paymentTab-header-button.active`)
      .classList.remove("active");
    target.classList.add("active");
    console.log(event);
    // this.currentTab = tab;
  }
  closeModal() {
    this.isModalShown = false;
  }
}
