import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventModel } from 'src/app/models/event.model';
import { EventService } from '../event.service';
import { AuthService } from 'src/app/common/auth.service';
import { UserModel } from 'src/app/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import { NotificationService } from 'src/app/common/notification.service';
import { DialogService } from 'src/app/common/dialog/dialog.service';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})
export class RsvpComponent implements OnInit {

  event:EventModel = null;
  currentUser: UserModel = null;
  myForm: FormGroup;
  isMobile: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private fb: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
  ) {
    this.myForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 768;
    this.route.params.subscribe(params => {
      this.init();
    });
  }

  private async init() {
    const id: string = this.route.snapshot.paramMap.get("eventId");
    this.event = await this.eventService.getWebEvent(id);
  }

  async onSubmit() {
    if (!this.myForm.valid) {
      this.markAllFieldsAsTouched(this.myForm);
      return;
    }
    const id: string = this.route.snapshot.paramMap.get("eventId");
    const firstName = this.myForm.get('firstName').value;
    const lastName = this.myForm.get('lastName').value;
    const email = this.myForm.get('email').value;
    const phone = this.composeNumber();
    
    if (!firstName || !lastName || !email || !phone) {
      return;
    }
    const user = await this.userService.createPotentialUser(
      firstName,
      lastName,
      email,
      phone,
      id,
    ) as any;
    if (!user) {
      this.notificationService.error('ERROR', 'An error occurred processing the user');
      return;
    }
    this.currentUser = this.authService.setCurrentUser(user);
    const res = await this.eventService.JoinPotentialUSer(id, email);
    if (!res) {
      this.notificationService.error('ERROR', 'An error occurred processing the event');
      return;
    }
    this.dialogService.success({
      title: 'Successfully RSVPed!',
      subtitle: 'You\'re all set to join table ',
      html: `
        <div style="font-size: 2px;">
          <h3 style="font-weight: bold; font-size: clamp(18px, 5vw, 25px);"><strong style="font-weight: bold">Next Steps:</strong></h3  style="font-weight: bold; font-size: clamp(18px, 5vw, 25px);">
          <ul>
            <li style="font-size: clamp(15px, 3vw, 20px);">
              Download the app to see who else is joining and receive table updates.
            </li>
            <br>
            <li style="font-size: clamp(15px, 3vw, 20px);">
              Ensure you use the <strong style="font-weight: bold">same email address</strong> to sign up.
            </li>
          </ul>
        </div>
      `,
    });
    setTimeout(() => {
      this.router.navigate(["/send-download-app"]);
    }, 1000);

  }
  composeNumber() {
    const number = this.myForm.get('phone').value
    const nationalNumber = number.nationalNumber.replace(/[\s()-]/g, '')
    return number.dialCode + nationalNumber
  }
  markAllFieldsAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

}
