import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { VenueService } from '../../venue/venue.service';
import { VenueModel } from '../../models/venue.model';
import { AuthService } from '../../common/auth.service';
import { UserModel } from '../../models/user.model';
import { MenuService } from '../../menu-item/menu.service';
import { MenuItemModel } from '../../models/menu-item.model';
import { VenueBookingComponent } from '../../venue/venue.booking.component';
import { UserRoles } from '../../data/user.roles.data';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from '../../common/notification.service';
import { MenuModel } from '../../models/menu.model';
import { EnumService } from '../../common/enum.service';
import { FeatureTypes } from '../../data/features.types.data';
import * as Moment from 'moment';
import { EventModel } from '../../models/event.model';
import { extendMoment } from 'moment-range';
import { GetTheAppPopupComponent } from '../../home/get-the-app-popup.component';
import { BasketService, IBasket } from '../basket/basket.service';
import { throttle } from 'lodash';
import { MobileService } from '../mobile.service';
import { neighborhood } from 'src/configs/neighborhood';

const moment = extendMoment(Moment);
@Component({
   selector: 'app-venue-profile',
   templateUrl: './menu.component.html',
   styleUrls: ['./menu.component.scss']
})
export class MobileOrderingMenuComponent implements OnInit, AfterViewInit, OnDestroy {

   @Output() created: EventEmitter<EventModel> = new EventEmitter();

   @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

   venue: VenueModel;
   eventId: string;
   schedule: any[];
   events: any[];
   menuTabKeys: MenuModel[];
   marker: any;
   bsModalRef: BsModalRef;
   currentUser: UserModel;
   menues: MenuModel[];
   lowestPrice: number;
   updateAvailabilityInProgress: boolean;
   showMenuInfo: boolean;
   currentTab: number;
   menueTabs = [];
   venueFeatures: any;
   venueNeighborhood: string;
   featureTypes: any;
   routeSubscription: any;
   seatNumber = 1;
   table: string;
   createTableDays: any;
   createTableTimeSlots: any;
   createInProgress: boolean;
   menusLoading = true;
   basket: IBasket;
   selectedTab: string;
   internal = false;
   private days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

   constructor(
      private venueService: VenueService,
      private route: ActivatedRoute,
      private menuService: MenuService,
      private authService: AuthService,
      private modalService: BsModalService,
      private notificationService: NotificationService,
      private enumService: EnumService,
      private cr: ChangeDetectorRef,
      private router: Router,
      private basketService: BasketService,
      private mobileService: MobileService
   ) { }

   ngOnInit() {
      this.basketService.getBasket().subscribe((value) => {
         this.basket = value;
      });
   }

   async ngAfterViewInit() {
      this.currentUser = this.authService.getCurrentUser();
      const slug: string = this.route.snapshot.paramMap.get('slug');
      this.table = this.route.snapshot.paramMap.get('table');
      this.venue = await this.venueService.getBySlug(slug);
      this.eventId = this.route.snapshot.paramMap.get('id');
      if (this.route.snapshot.fragment) {
         this.eventId = this.route.snapshot.fragment.slice(-24);
      }
      this.cr.detectChanges();
      this.schedule = this.buildVenueSchedule();
      this.updateAvailabilityInProgress = false;
      this.venueFeatures = {};
      this.featureTypes = FeatureTypes;
      this.menusLoading = true;
      await this.getFeatures();
      await this.loadMenus();
      await this.getMenuTypes();
      await this.getNeighborhood();
      this.showMenuInfo = false;
      this.currentTab = -1;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.routeSubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
            this.router.navigated = false;
         }
      });
      if (window.innerWidth > 1024) {
         this.staticTabs.type = 'pills';
      }
      $('.mobile-ordering-menu').on('scroll', (event) => {
         if (this.internal) {
            // this.internal = false;
            return;
         }
         this.handleScroll(event);
      });
   }

   ngOnDestroy() {
      this.routeSubscription = null;
   }

   checkVenueFeatures() {
      return Object.keys(this.venueFeatures).length;
   }

   getVenueName(name: string) {
      const maxLength = window.innerWidth > 1199 ? 29 : 25;
      if (name.length > 32) {
         const shorterName = name.substring(0, maxLength) + '...';
         return shorterName;
      }
      return name;
   }

   handleScroll(event) {
      const sections = this.menueTabs.map(item => item.key);
      if (($(`#${sections[sections.length - 1]}`) as any).visible()) {
         // handle last element
         this.selectedTab = sections[sections.length - 1];
         this.handleMenuBarScroll(sections[sections.length - 1 ], true);
         return;
      }
      let current;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < sections.length; i++) {
         if ( $('#' + sections[i]).offset().top <= $(window).scrollTop() + 200 ) {
            current = sections[i];
         }
      }
      this.selectedTab = current ? current : this.selectedTab;
      this.handleMenuBarScroll(this.selectedTab, false);
   }

   handleMenuBarScroll(section, isLast) {
      if (!section) {
         return;
      }
      // if entire element is visible just return
      if (($(`#menu-${section}`) as any).visible()) {
         return;
      }
      // // if partial element is visible
      // if (($(`#menu-${section}`) as any).visible(true)) {
      //    return;
      // }
      // if element is not visible
      if (!($(`#menu-${section}`) as any).visible()) {
         const offsetLeft = $(`#menu-${section}`).offset().left;
         $('#menu').scrollLeft(offsetLeft);
         this.internal = true;
         setTimeout(() => { this.internal = false; }, 500);
      }
   }

   scrollToPosition(elementId) {
      this.internal = true;
      this.selectedTab = elementId;
      document.getElementById(elementId).scrollIntoView();
      document.getElementById(elementId).scrollTop += 200;

      // if (this.checkIfIOS()) {
      //    const element = document.getElementById('mobile-ordering-menu');
      //    const scrollTop = document.getElementById('mobile-ordering-menu').scrollTop;
      //    element.scrollTop = scrollTop - 100;
      // } else {
      //    document.getElementById(elementId).scrollTop += 200;
      // }

      setTimeout(() => this.handleMenuBarScroll(elementId, false), 1000);
      setTimeout(() => { this.internal = false; }, 500);
   }

   checkIfIOS() {
      return [
         'iPad Simulator',
         'iPhone Simulator',
         'iPod Simulator',
         'iPad',
         'iPhone',
         'iPod'
      ].includes(navigator.platform);
      // // iPad on iOS 13 detection
      // || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
   }

   openGetTheAppPopup() {
      const initialState = {
         appType: 'user-app'
      };
      const bsModalRef = this.modalService.show(GetTheAppPopupComponent, { initialState });
      bsModalRef.setClass('modal-lg');
   }

   openMenuItemPage(item: MenuItemModel) {
      if (!item.workingHoursAvailable ) {
         return;
      }
      this.router.navigateByUrl(`/mobile-ordering/menu-item/${this.venue.slug}/${item.id}/${this.table}/${this.eventId}`);
   }

   addToBasket(item: MenuItemModel) {
      this.basketService.addToBasket(item, item.options[0]);
   }

   goToBasket() {
      this.router.navigateByUrl(`/mobile-ordering/basket/${this.venue.slug}/${this.table}/${this.eventId}`);
   }

   async getFeatures() {
      if (!this.venue.features || !this.venue.features.length) {
         return null;
      }
      const features = await this.enumService.getByTypes(['features-general', 'features-food', 'features-good-for', 'features-parking']);
      features.forEach(feature => {
         if (this.venue.features.indexOf(feature.key) !== -1) {
            if (!this.venueFeatures.hasOwnProperty(feature.type)) {
               this.venueFeatures[feature.type] = [feature.value];
            } else {
               this.venueFeatures[feature.type].push(feature.value);
            }
         }
      });
      return this.venueFeatures;
   }

   isMobile() {
      const userAgent = navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
         return true;
      }
      return false;
   }

   async getMenuTypes() {
      this.menusLoading = true;
      const menuTypes = await this.menuService.getMenuTypes(this.venue.id, true);
      menuTypes
      .filter((menuType) => menuType.disabled === false && menuType.menuItems > 0)
      .forEach((element) => {
         const items = this.getMenuItems(element.key);
         const menu = this.menues.filter(item => item.type === element.key)[0];
         if (items && items.length && this.doesMenuContainItems(items[0])) {
            if (this.menueTabs.length === 0) {
               this.selectedTab = element.key;
            }
            this.menueTabs.push({
               key: element.key,
               label: element.value,
               items,
               priority: menu.priority
            });
         }
      });
      this.menusLoading = false;
   }

   async loadMenus() {
      const allMenues = await this.menuService.getAllMenues(this.venue.id, true);
      this.menues = allMenues
         .filter(m => !m.disabled)
         .sort((a, b) => {
            if (a.priority > b.priority) {
               return 1;
            }
            if (b.priority > a.priority) {
               return -1;
            } else {
               return 0;
            }
         });
   }

   formatVenueCategories(categories) {
      let venueCategories = '';
      categories.forEach((el: string) => {
         const z = el.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
         venueCategories += `${z}, `;
      });
      if (venueCategories.length > 33) {
         venueCategories = venueCategories.slice(0, 33) + '...';
      } else {
         venueCategories = venueCategories.slice(0, venueCategories.length - 2);
      }
      return venueCategories;
   }

   toggleMenuInfo(i) {
      if (this.currentTab !== i) {
         this.currentTab = i;
         this.showMenuInfo = true;
      } else {
         this.showMenuInfo = !this.showMenuInfo;
      }
   }

   getLowestPrice(menu: MenuItemModel) {
      const prices = [];
      if (menu.options) {
         menu.options.forEach((option) => {
            if (option.price) {
               prices.push(option.price.value);
            }
         });
      }
      return Math.min(...prices);
   }

   selectByType(menu, type) {
      switch (type) {
         case 'glass':
            return menu.options.filter((option) => option.type && option.type === 'glass');
         case 'bottle':
            return menu.options.filter((option) => option.type && option.type === 'bottle');
         case 'cup':
            return menu.options.filter((option) => option.type && option.type === 'cup');
         default:
            return [];
         }
   }

   printItem(menu){
      const price = [];
      menu.forEach(element => {
         if (element.price) {
            price.push(element.price.value)
         }
      });
      return Math.min(...price);
   }

   async getNeighborhood() {
      // tslint:disable-next-line:max-line-length
      const neighborhoods = await this.enumService.getByTypes(neighborhood);
      if (!neighborhoods || !this.venue.neighborhood) {
         return this.venueNeighborhood = 'New York';
      }
      const element = neighborhoods.find((n) => n.key === this.venue.neighborhood);
      this.venueNeighborhood = element && element.value ? element.value : this.venue.neighborhood;
   }

   venueRating() {
      let priceRating = '';
      for (let i = 0; i < Number(this.venue.priceRating); i++) {
         priceRating += '$';
      }
      return priceRating;
   }

   async openGoogleRatingInBrowser(venue) {
      const googlePlaceId = await this.venueService.getVenuesGoogleInfo(venue.id);
      const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venue.name)}&query_place_id=${googlePlaceId}`;
      if (!googlePlaceId) {
        return;
      }
      return window.open(url, '_blank');
   }

   async updateMenuItemAvailability(menu: MenuItemModel) {
      this.updateAvailabilityInProgress = true;
      setTimeout(() => {
         this.menuService.updateAvailablity(this.venue.id, menu.id, menu.available, menu.type);
      });
      this.updateAvailabilityInProgress = false;
   }

   getWorkingHours(venue: VenueModel) {
      const day = this.getDayName(new Date());
      if (!venue.workingHours) {
         return 'N/A';
      }
      if (!venue.workingHours[day]) {
         return 'N/A';
      }
      if (!venue.workingHours[day].open) {
         return 'Closed';
      }
      if (!venue.workingHours[day].secondStart && !venue.workingHours[day].secondEnd) {
         return `${venue.workingHours[day].start} - ${venue.workingHours[day].end}`;
      }
      // tslint:disable-next-line: max-line-length
      return `${venue.workingHours[day].start} - ${venue.workingHours[day].end} ${venue.workingHours[day].secondStart} - ${venue.workingHours[day].secondEnd}`;
   }

   getAddress(venue: VenueModel) {
      if (!venue.location || !venue.location.address) {
         return 'N/A';
      }
      return venue.location.address;
   }

   getEventTimestamp(event: any) {
      if (!event.data) {
         return 'N/A';
      }
      if (event.data.date) {
         return event.data.date;
      }
      if (event.data.days && event.data.days.length) {
         const d = event.data.days.map((x) => {
            return this.firstCharToUppercase(x);
         });
         return `Every ${d.join(', ')}`;
      }
      return `${event.data.day}`;
   }

   getTypeLabel(venue: VenueModel): string {
      return 'Restaurant';
   }

   isAdmin() {
      return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
   }

   isOwner() {
      return (this.currentUser && this.currentUser.id === this.venue.ownerId);
   }

   isLoggedIn() {
      return this.currentUser;
   }

   getMenuItems(menu: string) {
      return this.menues.filter((x) => x.type === menu && !x.disabled);
   }

   openBookingModal() {
      if (!this.venue.ownerId) {
         this.notificationService.warning('Restaurant Claim', 'Restaurant\'s not yet in Cuculi network, no booking or ordering available');
         return;
      }
      const initialState = {
         venue: this.venue
      };
      this.bsModalRef = this.modalService.show(VenueBookingComponent, { initialState });
      this.bsModalRef.setClass('modal-lg');
   }


   shouldShowCategory(category) {
      const available = category.items.filter(item => item.available);
      return (category.items && category.items.length && available.length);
   }

   private doesMenuContainItems(menu: MenuModel) {
      if (menu.categories && menu.categories.length) {
         // tslint:disable-next-line:prefer-for-of
         for (let i = 0; i < menu.categories.length; i++) {
            if (menu.categories[i].items && menu.categories[i].items.length) {
               return true;
            }
         }
      }
      return false;
   }

   formatTime(time: string) {
      if (!time) {
         return '';
      }
      return time;
   }

   displayDay = (day: string) => {
      const newDay = day.slice(0, 3);
      return `${newDay[0].toUpperCase()}${newDay.slice(1, 3)}`;
   }

   displayHHTime(menuItem: any) {
      // tslint:disable: max-line-length
      if (menuItem && menuItem.startDate && menuItem.startDate.day && menuItem.startDate.workHours[0].start && menuItem.startDate.workHours[0].end) {
         return `${this.displayDay(menuItem.startDate.day)} ${this.formatTime(menuItem.startDate.workHours[0].start)} - ${this.formatTime(menuItem.startDate.workHours[0].end)}`;
      }

      if (menuItem && menuItem.workingHours && menuItem.workingHours.length && menuItem.workingHours[0].day) {
         if (menuItem.workingHours.length > 1) {
            return `${this.displayDay(menuItem.workingHours[0].day)} - ${this.displayDay(menuItem.workingHours[menuItem.workingHours.length - 1].day)} ${this.formatTime(menuItem.workingHours[0].start)} - ${this.formatTime(menuItem.workingHours[0].end)}`;
         }
         if (!menuItem.workingHours[0].start && !menuItem.workingHours[0].end) {
            return `${this.displayDay(menuItem.workingHours[0].day)}`;
         }
         if (menuItem.workingHours[0].start && !menuItem.workingHours[0].end) {
            return `${this.displayDay(menuItem.workingHours[0].day)} from ${this.formatTime(menuItem.workingHours[0].start)}`;
         }
         return `${this.displayDay(menuItem.workingHours[0].day)} ${this.formatTime(menuItem.workingHours[0].start)} - ${this.formatTime(menuItem.workingHours[0].end)}`;
      }
   }

   private buildVenueSchedule() {
      const schedule = this.days.map((x) => {
         return {
            label: x,
            key: x.toLowerCase(),
            value: 'NO'
         };
      });
      if (!this.venue || !this.venue.workingHours) {
         return schedule;
      }
      for (const key in this.venue.workingHours) {
         if (!this.venue.workingHours.hasOwnProperty(key)) {
            continue;
         }
         const d = schedule.find((x) => x.key === key);
         if (d) {
            if (this.venue.workingHours[key].open) {
               (this.venue.workingHours[key].secondStart && this.venue.workingHours[key].secondEnd)
                  // tslint:disable-next-line:max-line-length
                  ? d.value = `${this.venue.workingHours[key].start} - ${this.venue.workingHours[key].end} ${this.venue.workingHours[key].secondStart} - ${this.venue.workingHours[key].secondEnd}`
                  : d.value = `${this.venue.workingHours[key].start} - ${this.venue.workingHours[key].end}`;
            }
         }
      }
      return schedule;
   }

   private getDayName(date) {
      const day = date.getDay();
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      return days[day];
   }

   private firstCharToUppercase(s: string) {
      if (typeof s !== 'string') {
         return '';
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
   }
}
