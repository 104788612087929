import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { GenericFormComponent, GenericFormType, Lookup, GenericFormControl } from '../generic-form/generic.form.component';
import { MenuItemUpdateConstraints, MenuItemModel } from '../models/menu-item.model';
import { MenuService } from './menu.service';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { VenueModel } from '../models/venue.model';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators, FormGroup, FormArray } from '@angular/forms';
import { GenericMenuCategories } from '../data/generic.menu.categories.data';
import { FileUploadComponent } from '../file.upload/file.upload.component';
import { HourSlots } from '../data/hour.slots.data';
import { HappyHourModel } from '../models/happy-hour.model';
import { getDaysFromRange } from '../data/week.days.data';

@Component({
  selector: 'app-menu-item-edit',
  templateUrl: './menu-item.edit.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuItemEditComponent implements OnInit, AfterViewInit {

  @ViewChild('menuItemForm', { static: false }) menuItemForm: GenericFormComponent;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;

  updateInProgress: boolean;
  menu: MenuItemModel;
  venue: VenueModel;
  venueId: string;
  updateRules: GenericFormType[];
  loadMenus: any;
  categories: Lookup[];
  currentCategory: string;
  happyHours: HappyHourModel[];
  selectedHH: HappyHourModel;
  happyHourLookups: Lookup[];
  currentType: string;
  selectedOption: string;
  menues: Lookup[];
  defaultImage: boolean;
  imageRemoved: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    private menuService: MenuService
  ) { }

  async ngOnInit() {
    this.updateInProgress = false;
    this.updateRules = cloneDeep(MenuItemUpdateConstraints.slice(0));
    // add autocomplete category selection to the top of the menuItemUpdate rules
    // tslint:disable-next-line:max-line-length
    this.updateRules.unshift(GenericFormBuilder.autocomplete('category', 'Category', [Validators.required], [GenericMenuCategories.UNKNOWN], this.searchItems.bind(this), null, false, (x) => x.type !== 'happyHour'));
    const typeLookup = this.updateRules.find((x) => x.name === 'type');
    if (typeLookup) {
      (typeLookup as GenericFormControl).lookups = this.menues;
    }
    this.isDefaultImage();
  }

  ngAfterViewInit() {
    this.menuItemForm.fromModel<MenuItemModel>('MenuItemModel', 'MenuItemEditForm', this.menu);
    this.menuItemForm.myForm.get('category').setValue({ value: this.currentCategory, label: this.currentCategory });
    this.currentType = this.menu.type;
    this.selectedOption = this.menu.category;
    this.happyHourLookups = this.happyHours.map((x) => {
      if (!!x.startDate) {
        return {
          value: x.name,
          // tslint:disable-next-line:max-line-length
          label: `${x.name} @ ${x.startDate.day} ${(x.startDate.dayUntil ? '- ' + x.startDate.dayUntil : '')} ${this.getHourSlot(x.startDate.start)} - ${this.getHourSlot(x.startDate.end)}`,
          selected: (this.menu.category === x.name)
        };
      }
    });
    this.happyHourLookups.unshift({ value: null, label: 'Select Happy Hour' });
    const hoursForm: FormArray = this.menuItemForm.myForm.get('workingHours') as FormArray;
    if (!hoursForm) {
      return;
    }
    if (this.menu.workingHours && this.menu.workingHours.length) {
      this.menu.workingHours.forEach((h, i) => {
        const fg = (hoursForm.controls[i] as FormGroup);
        if (!fg) {
          return;
        }
        if (h.start) {
          fg.get('start').setValue(HourSlots.find((item) => item.label === h.start).value);
        }
        if (h.end) {
          fg.get('end').setValue(HourSlots.find((item) => item.label === h.end).value);
        }
      });
    }
  }

  searchItems(text: string) {
    if (!text) {
      return this.categories;
    }
    return this.categories.filter((c) => {
      return c.value.indexOf(text) !== -1;
    });
  }

  isDefaultImage() {
    this.defaultImage = !!(this.menu.images[0] === 'assets/images/meal-default-img.jpg');
    return;
  }

  removeImage() {
    this.menu.images[0] = 'assets/images/meal-default-img.jpg';
    this.isDefaultImage();
    this.imageRemoved = true;
    return;
  }

  async update() {
    if (this.menuItemForm.isValid()) {
      this.updateInProgress = true;
      try {
        let newMenu: MenuItemModel = this.menuItemForm.toModel<MenuItemModel>('MenuItemCreateForm', 'MenuItemModel');
        newMenu.id = this.menu.id;
        if (this.currentType === 'happyHour' && this.selectedHH) {
          newMenu.category = this.selectedHH.name;
        }
        this.isDefaultImage();
        if (this.defaultImage && this.imageRemoved) {
          newMenu.images = [];
        }
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
          newMenu.images = [];
          newMenu = await this.menuService.update(this.venue.id, newMenu);
          await this.menuService.uploadPhoto(this.venue.id, newMenu.id, this.fileUpload.filesData[0]);
        } else {
          newMenu = await this.menuService.update(this.venue.id, newMenu);
        }
        this.updateInProgress = false;
        this.loadMenus(this.currentType);
        this.bsModalRef.hide();
      } catch (error) {
        this.updateInProgress = false;
      }
    }
  }

  happyHourSelected($event) {
    const selected = $event.value;
    this.selectedHH = this.happyHours.find((x) => x.name === selected);
    if (this.selectedHH) {
      const menuModel = this.menuItemForm.toModel<MenuItemModel>('MenuItemCreateForm', 'MenuItemModel');
      menuModel.workingHours = [];
      const days = getDaysFromRange(this.selectedHH.startDate.day, this.selectedHH.startDate.dayUntil);
      days.forEach((day) => {
        menuModel.workingHours.push({
          day: day.value,
          open: true,
          splitWorkingHours: false,
          start: this.selectedHH.startDate.start,
          end: this.selectedHH.startDate.end
        });
      });
      this.menuItemForm.fromModel('MenuItemModel', 'MenuItemEditForm', menuModel);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  dataChanged(data) {
    this.currentType = data.type;
  }

  private getHourSlot(timestamp: string) {
    const hour = HourSlots.find((x) => x.value === timestamp);
    if (hour) {
      return hour.label;
    }
    return timestamp;
  }
}
