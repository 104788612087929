import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EnumService } from '../common/enum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-venue-booking',
  templateUrl: './venue.booking.component.html'
})
export class VenueBookingComponent implements OnInit {

  venue: any;

  constructor(public bsModalRef: BsModalRef, private router: Router) {
  }

  ngOnInit() {
  }

  eventCreated(event) {
    this.bsModalRef.hide();
    this.router.navigateByUrl(`/bookings/${event.id}/orders/add`);
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
