import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';

export interface DialogData {
  type: 'success' | 'error' | 'warning';
  title: string;
  subtitle?: string;
  message?: string;
  html?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {}

  success(data: Omit<DialogData, 'type'>): void {
    this.open({
      type: 'success',
      ...data
    });
  }

  error(data: Omit<DialogData, 'type'>): void {
    this.open({
      type: 'error',
      ...data
    });
  }

  warning(data: Omit<DialogData, 'type'>): void {
    this.open({
      type: 'warning',
      ...data
    });
  }

  private open(data: DialogData): void {
    this.dialog.open(DialogComponent, {
      data,
    });
  }

}
