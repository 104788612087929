import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { StripeCard } from 'stripe-angular';
import { GenericFormType, GenericFormComponent } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { UserModel } from '../models/user.model';
import { AuthService } from '../common/auth.service';
import { NotificationService } from '../common/notification.service';

@Component({
  selector: 'app-payment-create',
  templateUrl: './payment.create.component.html'
})
export class PaymentCreateComponent implements OnInit {

  @Input() hideNameInput: boolean;

  @ViewChild('addressForm', { static: false }) addressForm: GenericFormComponent;
  @ViewChild('stripeCard', { static: false }) stripeCard: StripeCard;

  addressFormRules: GenericFormType[] = [
    // GenericFormBuilder.text('name', 'Cardholder name', [Validators.required], false, null, 'col-md-6 inline-block'),
    // GenericFormBuilder.text('address', 'Cardholder address', [Validators.required], false, null, 'col-md-6 inline-block'),
    // GenericFormBuilder.text('city', 'Cardholder city', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('state', 'Country', [Validators.required], false, null, 'col-md-6 inline-block claim-restaurant'),
    GenericFormBuilder.text('postalCode', 'Zip Code', [Validators.required], false, null, 'col-md-6 inline-block claim-restaurant'),
  ];
  options: any = {
    hidePostalCode: true,
    disableLink:true
  };
  currentUser: UserModel;

  constructor(
    private authService: AuthService,
    private toasterService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser();
    if (!this.hideNameInput) {
      // tslint:disable-next-line:max-line-length
      this.addressFormRules.unshift(GenericFormBuilder.text('name', 'Cardholder name', [Validators.required], false, null, 'col-md-12 inline-block claim-restaurant'));
      setTimeout(() => {
        if (this.currentUser && this.currentUser.firstName && this.currentUser.lastName) {
          this.addressForm.fromModel<UserModel>('UserModel', 'NewCreditCard', this.currentUser);
        }
      }, 500);
    }
  }

  isValid() {
    return this.addressForm.isValid();
  }

  async getPaymentSource(name?: string, city?: string, address?: string): Promise<any> {
    if (!this.isValid()) {
      return null;
    }
    const data = this.addressForm.toObject();
    const result = await this.stripeCard.createToken({
      name: name ? name : data.name,
      address_city: city,
      address_line1: address,
      address_state: data.state,
      address_zip: data.postalCode
    });

    if (!result && this.stripeCard.invalid) {
      this.toasterService.warning('Invalid Card Data', this.stripeCard.invalid.message);
    }

    return result;
  }
}
