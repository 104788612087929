// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { HomeComponent } from '../home/home.component';
import { LoginComponent } from '../login/login.component';
import { VenueCreateComponent } from '../venue/venue.create.component';
import { UserCreateComponent } from '../user/user.create.component';
import { IntroComponent } from '../intro/intro.component';
import { VenueListComponent } from '../venue/venue.list.component';
import { VenueProfileComponent } from '../venue/venue.profile.component';
import { MenuItemCreateComponent } from '../menu-item/menu-item.create.component';
import { VenueEditComponent } from '../venue/venue.edit.component';
import { MenuItemEditComponent } from '../menu-item/menu-item.edit.component';
import { UserBookingsComponent } from '../user/user.bookings.component';
import { VenueBookingsComponent } from '../venue/venue.bookings.component';
import { UserInvitationsComponent } from '../user/user.invitations.component';
import { VenueGalleryComponent } from '../venue/venue.gallery.component';
import { EventOrdersComponent } from '../event/event.orders.component';
import { OrderCreateComponent } from '../order/order.create.component';
import { UserCreateFromInviteComponent } from '../user/user.create.from.invite.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { AuthGuard } from '../routing/auth.guard';
import { UserSettingsComponent } from '../user/user.settings.component';
import { VenueMenuComponent } from '../venue/venue.menu.component';
import { ReservationListContainerComponent } from '../reservation-list/reservation-list-container.component';
import { WaitersListComponent } from '../waiter/waiters.list.component';
import { AboutComponent } from '../about/about.component';
import { ForRestaurantComponent } from '../for-restaurant/for-restaurant.component';
import { PageComponent } from '../pages/page.component';
import { SearchComponent } from '../search/search.component';
import { MobileOrderingHomeComponent } from '../mobile-ordering/home/home.component';
import { MobileOrderingMenuComponent } from '../mobile-ordering/menu/menu.component';
import { MobileOrderingMenuItemComponent } from '../mobile-ordering/menu-item/menu-item.component';
import { MobileOrderingBasketComponent } from '../mobile-ordering/basket/basket.component';
import { MobileOrderingCheckoutComponent } from '../mobile-ordering/checkout/checkout.component';
import { VenueRegisterComponent } from '../venue/venue.register.component';
import { VenueClaimComponent } from '../venue/venue-claim/venue.claim.component';
import { GetTheAppScreenComponent } from '../home/get-the-app-screen.component';
import { EventProfileComponent } from '../event/event.profile.component';
import { EventTicketsComponent } from '../event/tickets/event.tickets.component';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { UnsubscribeFormComponent } from '../user/unsubscribe-form/unsubscribe.form.component';
import { ContactComponent } from '../contact/contact.component';
import { ReferralComponent } from '../referral/referral.component';
import { CaptureUserFromSocialMediaComponent } from '../capture-user-from-social-media/capture-user-from-social-media.component';
import { SendToDownloadAppComponent } from '../send-to-download-app/send-to-download-app.component';
import { DeleteAccountRequestComponent } from '../delete-account-request/delete-account-request.component';
import { SubscriptionComponent } from '../subscription/subscription.component';
import { SoulCampaignComponent } from '../soul-campaign/soul-campaign.component'
import { SoulCampaignDetailsComponent } from '../soul-campaign/soul-campaign-details/soul-campaign-details.component'
import { UnsubscribEmailsComponent } from '../unsubscribe-emails/unsubscribe-emails.component';
import { RsvpComponent } from '../event/rsvp/rsvp.component';
import { CheckoutRedirectComponent } from '../mobile-ordering/checkout-redirect/checkout-redirect.component';
import { PrivateEventProfileComponent } from '../event/private-event-profile/private-event-profile.component';
import { EventGuard } from './event.guard';
import { CancelPaymentComponent } from '../mobile-ordering/cancel-payment/cancel-payment.component';
import { QrPaymentPageComponent } from '../qr-payment-page/qr.payment.page.component';
import { QRPaymentTabComponent } from '../qr-payment-page/tab.payment/tab.payment.component';
const isMobile: boolean = !!(new RegExp('Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS', 'i').test(window.navigator.userAgent));

const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
      { path: '', component: HomeComponent },
      { path: 'intro', component: IntroComponent },
      { path: 'login', component: LoginComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'register-venue', component: VenueCreateComponent },
      // { path: 'register-venue/:slug/register-user', component: VenueUserRegisterComponent },
      { path: 'venue-register', component: VenueRegisterComponent },
      { path: 'venue-register/:slug/claim-restaurant', component: VenueClaimComponent },
      { path: 'register-user', component: UserCreateComponent },
      { path: 'register-user-quick', component: UserCreateComponent, data: { hideHeader: true, hideFooter: true, hidePayment: true } },
      { path: 'user-profile', component: UserSettingsComponent, canActivate: [AuthGuard] },
      { path: 'venues', component: VenueListComponent },
      { path: 'search', component: SearchComponent },
      { path: 'search/:type', component: SearchComponent },
      { path: 'venues/:slug', component: VenueProfileComponent },
      // { path: 'venues/:slug/claim-restaurant', component: VenueClaimComponent },
      { path: 'venues/:slug/add-menu-item', component: MenuItemCreateComponent },
      { path: 'venues/:slug/edit', component: VenueEditComponent, canActivate: [AuthGuard] },
      { path: 'venues/:slug/gallery', component: VenueGalleryComponent },
      { path: 'venues/:slug/menu-item/:menuId/edit', component: MenuItemEditComponent },
      { path: 'venues/:slug/bookings', component: VenueBookingsComponent },
      { path: 'venues/:slug/menu', component: VenueMenuComponent },
      { path: 'venues/:slug/waiters', component: WaitersListComponent },
      { path: 'bookings', component: UserBookingsComponent },
      { path: 'invitations', component: isMobile ? GetTheAppScreenComponent : UserInvitationsComponent, canActivate: isMobile ? [] : [AuthGuard], data: isMobile ? { hideFooter: true, hideHeader: true } : null },
      { path: 'invitations/:reservationId', component: GetTheAppScreenComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'bookings/:id', component: EventProfileComponent, canActivate: [EventGuard]},
      { path: 'events', component: SearchComponent},
      { path: 'events/:id', component: EventProfileComponent },
      { path: 'events/:id/tickets', component: EventTicketsComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'private-event/:id', component: PrivateEventProfileComponent },
      { path: 'bookings/:id/orders/add', component: OrderCreateComponent },
      { path: 'users/invitations/:id', component: GetTheAppScreenComponent },
      { path: 'reservations', component: ReservationListContainerComponent },
      { path: 'about', component: AboutComponent },
      { path: 'for-restaurants', component: ForRestaurantComponent },
      { path: 'mobile-ordering/home/:venueSlug/:table', component: MobileOrderingHomeComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/home/:venueSlug/:table/:id', component: MobileOrderingHomeComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/menu/:slug/:table', component: MobileOrderingMenuComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/menu/:slug/:table/:id', component: MobileOrderingMenuComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/menu-item/:slug/:menuId/:table', component: MobileOrderingMenuItemComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/menu-item/:slug/:menuId/:table/:id', component: MobileOrderingMenuItemComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/basket/:slug/:table', component: MobileOrderingBasketComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/basket/:slug/:table/:id', component: MobileOrderingBasketComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/checkout/:slug/:table', component: MobileOrderingCheckoutComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/checkout/:slug/:table/:id', component: MobileOrderingCheckoutComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/redirect/:id/:sessionId', component: CheckoutRedirectComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'mobile-ordering/cancel/:id/:sessionId', component: CancelPaymentComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'l/:page', component: PageComponent },
      { path: 'groups/:groupId', component: GetTheAppScreenComponent, data: { hideHeader: true, hideFooter: true } },
      { path: "verify-email/email/:email/code/:code", component: VerifyEmailComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'unsubscribe', component: UnsubscribeFormComponent, canActivate: [AuthGuard] },
      { path: 'referral', component: ReferralComponent },
      { path: 'subscribe-social-media', component: CaptureUserFromSocialMediaComponent },
      { path: 'send-download-app', component: SendToDownloadAppComponent },
      { path: 'request-delete-data', component: DeleteAccountRequestComponent, data: { hideHeader: true, hideFooter: true } },
      { path: 'subscription', component: SubscriptionComponent },
      { path: 'gift-card', component: SoulCampaignComponent, data: {shortSpace: true}},
      { path: 'gift-card/product-page/:slug', component: SoulCampaignDetailsComponent},
      { path: 'unsubscribeEmail/:id', component: UnsubscribEmailsComponent},
      { path: 'rsvp/:eventId', component: RsvpComponent},
      { path: 'mobile-qr/:slug/:tableNo', component: QrPaymentPageComponent, data: { hideHeader: false, hideFooter: true } },
      { path: 'mobile-qr/:slug/:tableNo/:eventId/:sessionId', component: QRPaymentTabComponent, data: { hideHeader: true, hideFooter: true } },
    ]
  },
  { path: '**', component: PageNotFoundComponent, data: { title: 'Page Not Found' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
