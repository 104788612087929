import { Injectable } from "@angular/core";
import { ApiService } from "../common/api.service";

@Injectable()
export class PaymentService {
  constructor(private apiService: ApiService) {}

  createCustomer(
    name: string,
    email: string,
    address: any,
    source: any,
    saveSource: boolean
  ): Promise<any> {
    return this.apiService.post(
      `/payment/customer`,
      {
        name,
        email,
        address,
        source,
        hideSource: !saveSource,
      },
      []
    );
  }

  createAccount(email: string, country: string): Promise<any> {
    return this.apiService.post(
      `/payment/merchant`,
      {
        country,
        email,
      },
      []
    );
  }

  async getTicketExpensesConfig(): Promise<any> {
    const config = await this.apiService.get(`/ticket-expenses-config`, []);
    return config;
  }
}

export interface IChargeData {
  amount: number;
  currency: string;
  source: string;
  description: string;
  vendor: string;
  customer: string;
  date: string;
  useCredit: boolean;
  tips: number;
  eventCreator: boolean;
}
