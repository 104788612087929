import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-get-the-app-popup',
  templateUrl: './get-the-app-popup.component.html',
})
export class GetTheAppPopupComponent implements OnInit {

  appType: string;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  async ngOnInit() {
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
