import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from './dialog.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  safeHtml: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sanitizer: DomSanitizer
  ) {
    if (this.data.html) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.html);
    }
  }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    });
    this.dialogRef.afterOpened().subscribe(() => {
      // setTimeout(() => {
      //   this.dialogRef.close();
      // }, 6000);
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
