import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-home-banner-component',
  templateUrl: './home.banner.component.html',
  styleUrls: ['./home.banner.component.scss']
})
export class HomeBannerComponent {

  constructor(
    private modalRef: BsModalRef,
    private router: Router
  ) { }

  close() {
      this.modalRef.hide();
  }

  goToTables() {
    this.router.navigateByUrl('/search/tickets');
    this.close();
  }
}
