import { Component, OnInit } from '@angular/core';
import { INotificationConfigType, NotificationConfigTypes } from '../data/notification.config.types';
import { UserService } from '../user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { event } from 'jquery';
import { NotificationService } from '../common/notification.service';
import { UserModel } from '../models/user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../common/auth.service';

@Component({
  selector: 'app-unsubscribemails',
  templateUrl: './unsubscribe-emails.component.html',
  styleUrls: ['./unsubscribe-emails.component.scss']
})
export class UnsubscribEmailsComponent implements OnInit {

  id:string;
  user: UserModel;
  loggedInUser: UserModel;

  constructor(
    private userService: UserService, 
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.loggedInUser = this.authService.getCurrentUser();
    if (this.loggedInUser) {
      this.user = await this.userService.getById(this.id);
    }
  }

  cancel() {
      this.router.navigateByUrl("");
  }

  async unsubscribe() {
    this.spinner.show();
    const dataToSend = [
      {
        type: NotificationConfigTypes.EVENT_ANNOUNCEMENT,
        email: false,
      },
      {
        type: NotificationConfigTypes.EVENT_TABLE,
        email: false,
      },
      {
        type: NotificationConfigTypes.BOOKING,
        email: false,
      },
      {
        type: NotificationConfigTypes.COMMUNICATION,
        email: false,
      },
      {
        type: NotificationConfigTypes.GENERAL,
        email: false,
      },
    ];

    for (const newConfig of dataToSend) {
      try {
        await this.userService.updateUserNotificationConfigs(this.id, [newConfig], "email Unsubscribe button");
      } catch (error) {
        console.error(`Error updating config for ${newConfig.type}:`, error);
      }
    }

    const resMailchimp = await this.userService.unsubscribeFromMailChimp(this.id);

    this.notificationService.success('successfully unsubscribed!');
    this.spinner.hide();
    setTimeout(() => {
      this.router.navigateByUrl("");
    }, 2000);
  }


}