import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';

@Injectable()
export abstract class BaseService<T> {

  constructor(
    protected apiService: ApiService,
    protected automapper: AutomapperService) {
  }

  async getAll(): Promise<T[]> {
    return this.apiService.get(`/${this.getApiPrefix()}`, [])
      .then((res) => {
        return this.automapper.mapArray<T>(this.getApiName(), this.getModelName(), res);
      });
  }

  async getById(id: string, token?: string): Promise<T> {
    return this.apiService.get(`/${this.getApiPrefix()}/${id}`, token ? [
      { key: 'Authorization', value: `Bearer ${token}` }
    ] : [])
      .then((res) => {
        return this.automapper.map<T>(this.getApiName(), this.getModelName(), res);
      });
  }

  async getPublicById(id: string): Promise<T> {
    return this.apiService.get(`/${this.getApiPrefix()}/public/${id}`, [])
      .then((res) => {
        return res; // this.automapper.map<T>(this.getApiName(), this.getModelName(), res);
      });
  }

  async search(query: any): Promise<T[]> {
    if (!query) {
      return this.getAll();
    }
    const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    return this.apiService.get(`/${this.getApiPrefix()}?${queryString}`, [])
      .then((res) => {
        return this.automapper.mapArray<T>(this.getApiName(), this.getModelName(), res);
      });
  }

  async create(data: T): Promise<T> {
    const payload = this.automapper.map(this.getModelName(), this.getApiName(), data);
    return this.apiService.post(`/${this.getApiPrefix()}`, payload, [])
      .then((res) => {
        return this.automapper.map<T>(this.getApiName(), this.getModelName(), res);
      });
  }

  async update(id: string, data: T): Promise<T> {
    delete (data as any).createdAt;
    delete (data as any).status;

    const payload = this.automapper.map(this.getModelName(), this.getApiName(), data);
    return this.apiService.put(`/${this.getApiPrefix()}/${id}`, payload, [])
      .then((res) => {
        return this.automapper.map<T>(this.getApiName(), this.getModelName(), res);
      });
  }

  async delete(id: string): Promise<boolean> {
    return this.apiService.delete(`/${this.getApiPrefix()}/${id}`, [])
      .then((res) => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  protected abstract getModelName(): string;
  protected abstract getApiName(): string;
  protected abstract getApiPrefix(): string;
}
