import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';
import { MenuItemModel } from '../models/menu-item.model';
import { MenuModel } from '../models/menu.model';

@Injectable()
export class MenuService {

  constructor(
    private apiService: ApiService,
    private automapper: AutomapperService
  ) {
  }

  async create(data, venueId): Promise<MenuItemModel> {
    data.type = data.type ? data.type : 'lunch';
    const payload = this.automapper.map(this.getModelName(), this.getApiName(), data);
    return this.apiService.post(`/venue/${venueId}/menu-item`, payload, [])
      .then((res) => {
        return this.automapper.map<MenuItemModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  async getAll(venueId: string): Promise<MenuItemModel[]> {
    return this.apiService.get(`/venue/${venueId}/menu-item`, [])
      .then((res) => {
        return this.automapper.mapArray<MenuItemModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  async getById(venueId: string, id: string): Promise<MenuItemModel> {
    return this.apiService.get(`/venue/${venueId}/menu-item/${id}`, [])
      .then((res) => {
        return this.automapper.map<MenuItemModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  async search(venueId: string, query: any): Promise<MenuItemModel[]> {
    if (!query) {
      return this.getAll(venueId);
    }
    const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    return this.apiService.get(`/venue/${venueId}/menu-item?${queryString}`, [])
      .then((res) => {
        return this.automapper.mapArray<MenuItemModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  async update(venueId: string, data: MenuItemModel): Promise<MenuItemModel> {
    const payload = this.automapper.map(this.getModelName(), this.getApiName(), data);
    return this.apiService.put(`/venue/${venueId}/menu-item/${data.id}`, payload, [])
      .then((res) => {
        return this.automapper.map<MenuItemModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  async delete(id: string): Promise<boolean> {
    return this.apiService.delete(`/menu-item/${id}`, [])
      .then((res) => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  async uploadPhoto(venueId: string, menuId: string, payload: any): Promise<any> {
    const url = `/venue/${venueId}/menu-item/${menuId}/image`;
    let response;
    try {
      response = await this.apiService.post(url, payload, []);
    } catch (error) {
      throw error;
    }
    return response;
  }

  async updateAvailablity(venueId: string, menuId: string, availability: boolean, menuType: string): Promise<boolean> {
    const payload = { value: availability, type: menuType };
    return this.apiService.put(`/venue/${venueId}/menu-item/${menuId}/available`, payload, [])
      .then((res) => {
        return res.value;
      });
  }

  async getByType(venueId: string, type: string, excludeUnavailable?: boolean): Promise<any> {
    let url = `/venue/${venueId}/menu/${type}`;
    if (excludeUnavailable) {
      url += `?excludeUnavailable=${excludeUnavailable.toString()}`;
    }
    return this.apiService.get(url, [])
      .then((res) => {
        return this.automapper.mapArray<MenuModel>('MenuApi', 'MenuModel', res);
      });
  }

  async getAllMenues(venueId: string, excludeUnavailable?: boolean): Promise<MenuModel[]> {
    let url = `/venue/${venueId}/menues`;
    if (excludeUnavailable) {
      url += `?excludeUnavailable=${excludeUnavailable.toString()}`;
    }
    return this.apiService.get(url, [])
      .then((res) => {
        return this.automapper.mapArray<MenuModel>('MenuApi', 'MenuModel', res);
      });
  }

  async updateMenuByType(venueId: string, data: MenuModel): Promise<any> {
    const payload = this.automapper.map('MenuModel', 'MenuApi', data);
    return this.apiService.put(`/venue/${venueId}/menu/${data.type}`, payload, [])
      .then((res) => {
      });
  }

  async getMenuTypes(venueId: string, excludeDisabled: boolean): Promise<any> {
    const res = await this.apiService.get(`/venues/${venueId}/menu-types?excludeDisabled=${excludeDisabled}`, []);
    res.sort((x, y) => {
      if (x.priority > y.priority) {
        return 1;
      }
      if (x.priority < y.priority) {
        return -1;
      }
      return 0;
    });
    return res;
  }

  protected getModelName(): string {
    return 'MenuItemModel';
  }

  protected getApiName(): string {
    return 'MenuItemApi';
  }
}
