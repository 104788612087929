import { Component, OnInit, HostListener, Host, ElementRef } from '@angular/core';
import { AuthService } from '../common/auth.service';
import { VenueService } from '../venue/venue.service';
import { BehaviorSubject } from 'rxjs';
import { UserModel } from '../models/user.model';
import { Router } from '@angular/router';
import { UserRoles } from '../data/user.roles.data';
import { EventModel } from '../models/event.model';
import { UserService } from '../user/user.service';
import { VenueModel } from '../models/venue.model';
import { BsModalService } from 'ngx-bootstrap';
import { LoginComponent } from '../login/login.component';
import { ContactModalComponent } from '../contact/contact.modal.component';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-layout-header',
  templateUrl: './layout.header.component.html',
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class LayoutHeaderComponent implements OnInit {

  sidebar: boolean;
  isSticky: boolean;
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUser: UserModel;
  activeEvent: EventModel;
  venues: VenueModel[];
  displayHeader = true;
  homePage = environment.homePage;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private venueService: VenueService,
    private modalService: BsModalService,
    private eref: ElementRef,
    private router: Router,
    ) {
    this.sidebar = false;
  }

  async ngOnInit() {
    this.checkCurrentUserAndSubscribeForChanges();
    this.shouldShowHeader();
    this.checkScroll();
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = this.isMobile() || window.pageYOffset >= 50;
  }

  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.closeSideBar();
    }
  }

  toggleSideBar() {
    const target = document.querySelector('.navbar-toggler');
    target.classList.toggle('show');
    this.sidebar = !this.sidebar;
  }

  closeSideBar() {
    const target = document.querySelector('.navbar-toggler');
    target.classList.remove('show');
    this.sidebar = false;
  }

  isMobile() {
    return window.innerWidth < 992;
  }

  shouldShowHeader() {
    if (this.router.url.indexOf('claim-restaurant') !== -1 || this.router.url.indexOf('venue-register') !== -1) {
      this.displayHeader = false;
      return false;
    }
    this.displayHeader = true;
    return true;
  }

  isVenueOwner() {
    return this.currentUser && this.currentUser.venueIds && this.currentUser.venueIds.length;
  }

  isAdmin() {
    return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
  }

  openLoginModal() {
    const initialState = {
      class: 'modal-dialog-border-bottom'
    };
    const bsModalRef = this.modalService.show(LoginComponent, initialState);
  }

  openContactModal() {
    const initialState = {};
    this.modalService.show(ContactModalComponent, initialState);
  }

  async checkForActiveEvent() {
    return this.activeEvent ? true : false;
  }

  goToOrderNowPage() {
    this.router.navigateByUrl(`/bookings/${this.activeEvent.id}/orders/add`);
  }

  async logout() {
    await this.authService.logout();
    this.sidebar = false;
    this.router.navigateByUrl('/');
  }

  private async checkCurrentUserAndSubscribeForChanges() {
    this.isLoggedIn = await this.authService.isLoggedIn();
    if (this.isLoggedIn.getValue()) {
      this.currentUser = this.authService.getCurrentUser();
      this.activeEvent = await this.userService.getActiveEvent(this.currentUser.id);
    }
    this.isLoggedIn.subscribe(async (value) => {
      if (value) {
        this.currentUser = this.authService.getCurrentUser();
        if (this.isVenueOwner()) {
          this.venues = await this.venueService.getAllUserVenuesByVenueIds(this.currentUser.venueIds);
          this.venues = this.venues.sort((a, b) => a.name > b.name as any);
        }
        this.activeEvent = await this.userService.getActiveEvent(this.currentUser.id);
      }
    });
  }
}
