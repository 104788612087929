import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { QrPaymentPageComponent } from './qr.payment.page.component';
import { QRPaymentTabComponent } from './tab.payment/tab.payment.component';
import { QrMenuComponent } from './qr-menu/qr.menu.component';
import { TabsModule } from 'ngx-bootstrap';
import { PaymentSuccesModalComponent } from './paymet-success-modal/payment.success.modal.component';

@NgModule({
  declarations: [
    QrPaymentPageComponent,
    QRPaymentTabComponent,
    QrMenuComponent,
    PaymentSuccesModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    FileUploadModule,
    MultiSelectModule,
    DropdownModule,
    TabsModule
  ],
  exports: [
    QrMenuComponent
  ]
})
export class QrPaymentModule { }
