import { Component, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import {
  GenericFormComponent,
  GenericFormType,
} from "../generic-form/generic.form.component";
import { GenericFormBuilder } from "../generic-form/generic.form.builder";
import { ActivatedRoute, Router } from "@angular/router";
import { CaptureFromSocialMediaService } from "./capture-from-social-media.service";
import { take } from 'rxjs/operators';

@Component({
  selector: "app-capture-user-from-social-media",
  templateUrl: "./capture-user-from-social-media.component.html",
  styleUrls: ['./capture-user-from-social-media.component.scss']
})
export class CaptureUserFromSocialMediaComponent implements OnInit {
  @ViewChild("genericForm", { static: false }) genericForm: GenericFormComponent;

  titleLabel =
    "Gather & Dine With Ease Your Passport to New Firendships!";

  fields: GenericFormType[];

  comeFromSocialMedia: string;
  tableName: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public isMobile: boolean;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private captureFromSocialMediaService: CaptureFromSocialMediaService
  ) {}

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.getScreenWidth < 768;
    this.fields = [
      GenericFormBuilder.text(
        "name",
        "Name",
        [Validators.required],
        false,
        null,
        "col-md-6 inline-block socialMedia"
      ),
      GenericFormBuilder.text(
        "email",
        "Email",
        [Validators.required, Validators.email],
        false,
        null,
        "col-md-6 inline-block socialMedia"
      ),
    ];

    this.activateRoute.queryParamMap.pipe(take(1)).subscribe((paramsMap) => {
      this.comeFromSocialMedia = paramsMap.get("socialmedia");
      this.tableName = paramsMap.get("tableName");
    });
  }

  goToDownload() {
    let firstName = '';
    let lastName = '';
    const parts = this.genericForm.myForm.get("name").value.split(' ');
    if (parts.length > 1) {
      firstName = parts[0];
      lastName = parts.slice(1).join(' ');
    } else {
      firstName = this.genericForm.myForm.get("name").value;
      lastName = ' ';
    }
    this.captureFromSocialMediaService
      .addSocialMediaUser(
        this.genericForm.myForm.get("email").value,
        firstName,
        lastName,
        this.comeFromSocialMedia,
        this.tableName
      )
      .subscribe({
        next: (response) => this.router.navigate(["/send-download-app"]),
        error: (error) => console.error(error),
      });
  }
}
